import 'core-js/stable';
import 'regenerator-runtime/runtime';

if (window.document && window.document.createElement) {
  const script = window.document.createElement('script');
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxR90IUCTodO0qOpJZjimMgIP_Z5dhjQo&libraries=places';
  script.type = 'text/javascript';
  window.document.head.appendChild(script);
}
